    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Inconsolata', monospace, Verdana, 'Segoe UI', sans-serif;
        background: #101d30;
    }
    
    @keyframes loadIn {
        0% {
            transform: translateY(80px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    
    .first-word {
        opacity: 0;
        transform: translateY(80px);
        animation: loadIn 0.5s ease 1s forwards;
    }
    
    .second-word {
        opacity: 0;
        transform: translateY(80px);
        animation: loadIn 0.5s ease 1.10s forwards;
    }
    
    .third-word {
        opacity: 0;
        transform: translateY(80px);
        animation: loadIn 0.5s ease 1.20s forwards;
    }
    
    .fourth-word {
        opacity: 0;
        transform: translateY(80px);
        animation: loadIn 0.5s ease 1.40s forwards;
    }
    
    .fifth-word {
        opacity: 0;
        transform: translateY(80px);
        animation: loadIn 0.5s ease 1.50s forwards;
    }
    
    @keyframes loadInDown {
        0% {
            transform: translateY(-80px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    
    .nav-dropdown {
        transform: translateY(-80px);
        animation: loadInDown 0.5s ease .60s forwards;
    }
    
    @keyframes loadInRight {
        0% {
            transform: translateX(-500px);
        }
        100% {
            background-color: rgba(0, 0, 0, 0);
            transform: translateX(0px);
        }
    }
    
    .moveIn-right {
        transform: translateX(-600px);
        animation: loadInRight 0.8s ease 1.60s forwards;
    }
    
    #globalEFX {
        margin: 0px 30px 0px 10px;
        z-index: 999;
        color: #aaaaaa;
        background-color: rgba(0, 0, 0, 0);
    }
    
    .globalEffect1 {
        background-color: rgba(0, 0, 0, 0);
    }
    
    .globalEffect1:hover {
        color: #ad7c18;
        cursor: pointer;
        transform: scale(1.35);
        transition: all 0.2s ease-in-out;
    }
    
     ::-webkit-scrollbar-track {
        border: 1px solid rgba(90, 115, 155, 0.452);
    }
    
     ::-webkit-scrollbar {
        width: 12px;
        opacity: .1;
    }
    
     ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        border: 1px solid #af832a;
        background: rgba(255, 255, 255, 0.1);
    }
    
    .featureProject {
        margin: 20px 20px 20px 20px;
        background: none;
        font-weight: 700;
    }
    
    .appTitle {
        margin: 20px 20px 20px 20px;
        background: none;
        font-weight: bold;
        font-size: 2rem;
    }
    
    .appDescription {
        margin: 20px 0px 20px 0px;
        padding: 20px;
        background: #101d30;
    }
    
    @media screen and (max-width: 1000px) {
        .appDescription {
            box-shadow: none;
        }
    }
    
    .languagesUsed {
        margin: 20px 20px 20px 20px;
        background: none;
        display: flex;
    }
    
    .projectFile {
        margin: 20px 20px 20px 20px;
        background: none;
    }
    
    .projectLink {
        margin: 20px 20px 20px 20px;
        background: none;
    }
    
    .tech {
        background-color: rgba(0, 0, 0, 0);
    }
    
    @keyframes fadeInOnly {
        0% {}
        100% {
            opacity: 1;
            visibility: visible;
        }
    }
    
    .Fade-In-Only {
        opacity: 0;
        visibility: hidden;
        animation: fadeInOnly 0.6s ease 2.3s forwards;
    }
    
    @keyframes loadInS {
        0% {
            transform: translateY(80px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    
    .first-wordS.is-visible {
        opacity: 0;
        transform: translateY(80px);
        animation: loadInS 1.6s ease 0.5s forwards;
    }
    
    @keyframes loadUp0 {
        0% {
            transform: translateY(80px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    
    .TechImg0.is-visible {
        opacity: 0;
        transform: translateY(80px);
        animation: loadUp0 1.6s ease 0.2s forwards;
    }
    
    @keyframes loadUp1 {
        0% {
            transform: translateY(80px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    
    .TechImg1.is-visible {
        opacity: 0;
        transform: translateY(80px);
        animation: loadUp1 1.6s ease 0.2s forwards;
    }
    
    @keyframes loadUp2 {
        0% {
            transform: translateY(80px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    
    .TechImg2.is-visible {
        opacity: 0;
        transform: translateY(80px);
        animation: loadUp2 1.6s ease 0.2s forwards;
    }
    /*Filter*/
    
    .cell {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        cursor: cell;
    }
    
    .sepia,
    .sepia2 {
        -webkit-filter: grayscale(2);
        filter: grayscale(2);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    
    .sepia:hover,
    .sepia2:hover {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
        width: 100%;
    }
    
    .sepia {
        position: absolute;
        width: 90%;
        object-fit: contain;
        height: auto;
    }
    
    .sepia2 {
        position: absolute;
        width: 90%;
        right: 0px;
        margin-right: 0%;
        object-fit: contain;
        height: auto;
    }
    
    @media screen and (max-width: 1000px) {
        .sepia,
        .sepia2 {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    @media screen and (max-width: 768px) {
        .sepia,
        .sepia2 {
            object-fit: cover;
        }
    }
    
    @keyframes scroll {
        0% {
            height: 0
        }
        100% {
            height: 100%;
        }
    }
    
    @keyframes type {
        from {
            width: 0;
        }
    }
    
    @keyframes type2 {
        0% {
            width: 0;
        }
        50% {
            width: 0;
        }
        100% {
            width: 100;
        }
    }
    
    @keyframes blink {
        to {
            opacity: .0;
        }
    }
    
    @keyframes scan {
        from {
            top: 0
        }
        to {
            top: 100%
        }
    }
    
    @keyframes flicker {
        0% {
            opacity: 0.15795
        }
        5% {
            opacity: 0.31511
        }
        10% {
            opacity: 0.94554
        }
        15% {
            opacity: 0.2469
        }
        20% {
            opacity: 0.62031
        }
        25% {
            opacity: 0.0293
        }
        30% {
            opacity: 0.00899
        }
        35% {
            opacity: 0.5344
        }
        40% {
            opacity: 0.12778
        }
        45% {
            opacity: 0.52042
        }
        50% {
            opacity: 0.3823
        }
        55% {
            opacity: 0.2198
        }
        60% {
            opacity: 0.9383
        }
        65% {
            opacity: 0.86615
        }
        70% {
            opacity: 0.68695
        }
        75% {
            opacity: 0.55749
        }
        80% {
            opacity: 0.96984
        }
        85% {
            opacity: 0.0361
        }
        90% {
            opacity: 0.24467
        }
        95% {
            opacity: 0.08351
        }
        100% {
            opacity: 0.54813
        }
    }
    /*--------------------------Terminal---------------------------*/
    
    @import url('https://fonts.googleapis.com/css2?family=Vast+Shadow&display=swap');
    .Rtro {
        font-size: 6vw;
        font-family: 'Vast Shadow', cursive;
    }
    
    .console {
        background-color: transparent;
    }
    
    .console-output {
        background: none;
        background-color: transparent;
        overflow: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
        overflow-y: scroll;
        max-height: 48vh;
    }
    
    .input-prompt {
        color: #6b0905;
        background-color: transparent;
    }
    
    input {
        color: #ad7c18;
        border: none;
        outline: none;
        background-color: transparent;
        font-weight: 500;
        font-size: 17px;
    }
    
    div #userTag {
        color: #6b0905;
        background-color: transparent;
    }
    
    div text {
        background-color: transparent;
    }
    
    div text p h4 {
        background-color: transparent;
    }
    
    div text p {
        background-color: transparent;
    }
    
    div text p a {
        background-color: transparent;
    }
    
    div text p ul {
        display: inline-block;
        list-style-type: none;
        background-color: transparent;
    }
    
    div text p ul li {
        background-color: transparent;
        justify-content: end;
        float: left;
        width: 175px;
    }
    
    span {
        background-color: transparent;
    }
    
    .Grid {
        display: grid;
        grid-template-columns: 1fr 3fr;
    }
    
    @media (max-width: 1000px) {
        .Grid {
            display: grid;
            grid-template-columns: 1fr;
            max-width: fit-content;
        }
    }
    
    .pointer {
        cursor: pointer;
    }
    
    .pointer:hover {
        color: #ad7c18;
    }
    
    a.pointer:active {
        color: #6b0905;
    }
    
    .Fade-In-Only {
        background: transparent;
    }